import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Footer from '../../components/footer';
import Wrapper from '../../components/wrap';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Logo from "../../images/clubworx/logo.inline.svg";

const IndexPage = () => {
  return (
    <Layout navtype="dark" title="Clubworx">
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true" offset={0}>
        <StaticImage
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../../images/clubworx/head.jpg"
          alt="Clubworx"
          className="hero-image"
        />
      </AnimationOnScroll>
      <Wrapper className="first">
        <Logo className="project-logo" alt="ClubWorx" />
      </Wrapper>
      <AnimationOnScroll animateIn="animate__wrapInUp" animateOnce="true">
      <Wrapper >
        <p>
          2015-2019. <a href="https://www.clubworx.com" target="_blank" rel="noreferrer">ClubWorx</a> is Australia-based startup with up to 25 employees provides a cloud-based management solution for studios, gyms, and martial arts schools, generating an annual revenue of $6.5M.
        </p>

        <strong className="sub-title">My role and the team</strong>
        <h3>The make it perfect to minimize risk</h3>
        <p>
          I joined the project in the active development stage as their first UX designer. Clubworx has already been launched in MVP version. Working in a close-knit team with one of the co-founders (CEO), CTO and one more front-end engineer, I took care of the user experience across all products.
          <br/>
          As a growing startup, it's important to have a constantly evolving user model with strong feedback loops to aid your team in understanding present user behaviors and if the solution you're collectively putting effort into is solving the value in which they seek to satisfy their motivation.
          Over the years, the product has grown and changed, as have my areas of responsibility.
          I built prototypes inside applications using AngularJS, analyzed users feedbacks and enhanced the user experience, found a balance between better experience and quick integration (3rd party integrations), designed information architecture, wireframes, made audit and review launched features etc.
        </p>
        <strong className="sub-title">Key focus area</strong>
        <h3>Product design</h3>
        <p>
          I designed all Clubworx’s products, such as the <a href="https://www.clubworx.com/product" rel="noreferrer" target="_blank">web app</a> (Gym owner and management staff), <a href="https://www.clubworx.com/product/new-member-app" rel="noreferrer" target="_blank">Gym Member App</a>, <a href="https://intercom.help/clubworx/en/collections/54303-clubworx-user-guide#point-of-sale-pos" rel="noreferrer" target="_blank">Point of Sale</a>, <a href="https://www.clubworx.com/blog/3-ways-to-easily-track-attendance-with-clubworx" rel="noreferrer" target="_blank">Self-Checkin Kiosks</a>, <a href="https://intercom.help/clubworx/en/articles/3313771-how-to-set-up-your-free-hosted-website" rel="noreferrer" target="_blank">Gym Public Web apps</a>. 
          <br/>
          Great importance was given to user research at all times. We usually ran user interviews and used other research techniques to make sure we were addressing real business needs and pain points. We also used these learnings to redefine the information architecture of our products, to make sure that we can grow. These principles were key to the deep redesign of the gym owners and management staff the app and developing new features and sub-products like: Sequences, Waivers & Smart Forms, Point of Sale, Custom Reports wizard, Workout Tracker, Styles and Ranks, Roll Call, Assessments and 3rd-party integrations.
        </p>
      </Wrapper>
      <div className="screens-with-animations">
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <div className="screen screen-inline">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/clubworx/empty_dashboard.png"
              alt="Clubworx — Empty dashboard"
            />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
          <div className="screen screen-inline">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/clubworx/member_payments.png"
              alt="Clubworx — Members payments"
            />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <div className="screen screen-inline">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/clubworx/trial_expired.png"
              alt="Clubworx — Trial Expired"
            />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
          <div className="screen screen-inline">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/clubworx/gradings.png"
              alt="Clubworx — Gradings"
            />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <div className="screen screen-inline">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/clubworx/sequences.png"
              alt="Clubworx — Sequences"
            />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
          <div className="screen screen-inline">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/clubworx/500.png"
              alt="Clubworx — 500 error"
              />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <div className="screen screen-inline">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/clubworx/calendar.png"
              alt="Clubworx — Calendar"
            />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
          <div className="screen screen-inline">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/clubworx/connection_suggest.png"
              alt="Clubworx — Connection suggest"
            />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <div className="screen screen-inline">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/clubworx/report.png"
              alt="Clubworx — Report"
            />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
          <div className="screen screen-inline">
            <div className="screen-bar"></div>
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/clubworx/privacy_mode.png"
              alt="Clubworx — Dashboard Privacy Mode"
            />
          </div>
        </AnimationOnScroll>
      </div>
      <br />
      <br />
      <div className="center">
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
          <div className="device">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/clubworx/self_checkin.png"
              alt="Clubworx — Self Checkin Kiosk"
            />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
          <div className="device">
            <StaticImage
              placeholder="blurred"
              formats={["AUTO", "WEBP", "AVIF"]}
              src="../../images/clubworx/kiosk.png"
              alt="Clubworx — Kiosk"
            />
          </div>
        </AnimationOnScroll>
      </div>

      <Wrapper>
        <br/>
        <strong className="sub-title">Outcomes</strong>
        <h3>It was a perfect way of minimizing risk</h3>
        <p>Using User Research (Customer Development), we easily found out about the current needs and pains of the clients. This approach allowed us to adapt the product and multiply the revenue, and grew Clubworx into a stable business.</p>
        <br/>
        <AnimationOnScroll animateIn="animate__slideInUp" animateOnce="true">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../images/clubworx/david.png"
            alt="David Feedback"
          />
        </AnimationOnScroll>
      </Wrapper>
      </AnimationOnScroll>
      <Footer />
    </Layout>
  )
}

export default IndexPage
